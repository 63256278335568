import Axios from 'axios';
import { EventTask } from '@/types/Event';
import { MOCK_GET_EVENT_TASKS } from '@/requests/Event/getTasks/getMockEventTasks';
import { Item } from '@/types/Item';
import axiosRetry from 'axios-retry';

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});

export interface GetEventTasksResponse {
    tasks: EventTask[];
    rewardItems: Item[];
}

export default async function getEventTasks(): Promise<GetEventTasksResponse> {
    if (process.env.NODE_ENV === 'development') {
        // @ts-ignore
        return MOCK_GET_EVENT_TASKS;
    }

    return (await Axios.get<GetEventTasksResponse>('/api/event/tasks')).data;
}
