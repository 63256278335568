import axiosRetry from 'axios-retry';
import Axios from 'axios';

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});

export async function countMetricPayment(id: number): Promise<void> {
    if (process.env.NODE_ENV !== 'development') {
        await Axios.post(`/api/payment/${id}/count`);
    }
}
