import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
  linkExactActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/Layout.vue'),
      children: [
        {
          path: '/',
          component: () => import('@/pages/Index.vue')
        },
        {
          path: '/Event',
          component: () => import('@/pages/Event.vue')
        },
        {
          path: '/contract',
          component: () => import('@/pages/Contract.vue')
        },
        {
          path: '/upgrade',
          component: () => import('@/pages/Upgrade.vue')
        },
        {
          path: '/promotions',
          component: () => import('@/pages/Promotions.vue')
        },
        {
          path: '/account',
          component: () => import('@/pages/user/Account.vue')
        },
        {
          path: '/profile/:id',
          component: () => import('@/pages/user/PublicProfile.vue')
        },
        {
          path: '/case/:id',
          component: () => import('@/pages/Case.vue')
        },
        {
          path: '/user-agreement',
          component: () => import('@/pages/seo/UserAgreement.vue')
        },
        {
          path: '/privacy-policy',
          component: () => import('@/pages/seo/PrivacyPolicy.vue')
        },
        {
          path: '/cookies-policy',
          component: () => import('@/pages/seo/CookiesPolicy.vue')
        },
        {
          path: '/contacts',
          component: () => import('@/pages/seo/Contacts.vue')
        },
        {
          path: '/payment/success',
          component: () => import('@/pages/payment/Success.vue')
        },
        {
          path: '/payment/error',
          component: () => import('@/pages/payment/Error.vue')
        }
      ]
    },
    {
      path: '*',
      redirect: '/404',
      component: () => import('@/Layout.vue'),
      children: [
        {
          path: '/404',
          name: 'error404',
          component: () => import('@/pages/seo/NotFound.vue')
        }
      ]
    }
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
