import Axios from 'axios';
import { MOCK_GET_USER_ACCOUNT } from '@/requests/User/getUser/mockGetUserAccount';
import { MOCK_GET_USER_PROFILE } from '@/requests/User/getUser/mockGetUserProfile';
import axiosRetry from 'axios-retry';
import { UserAccount, UserProfile } from '@/types/User';

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});

export async function getUserAccount(): Promise<UserAccount> {
    let user: UserAccount;
    if (process.env.NODE_ENV === 'development') {
        user = MOCK_GET_USER_ACCOUNT;
    } else {
        user = (await Axios.get<UserAccount>('/api/user')).data;
    }

    return user;
}

export async function getUserProfile(uuid: string): Promise<UserProfile> {
    let user: UserProfile;
    if (process.env.NODE_ENV === 'development') {
        user = MOCK_GET_USER_PROFILE;
    } else {
        user = (await Axios.get<UserProfile>(`/api/user/${uuid}`)).data;
    }

    return user;
}
