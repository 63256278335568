import { Module, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import getPromotions from '@/requests/Promotions/getPromotions/getPromotions';
import { Promotion } from '@/types/Promotion';

export interface PromotionsState {
    promotions: Promotion[];
    isLoading: boolean;
}

@Module({namespaced: true, name: 'promotions', store})
export class PromotionsModule extends VuexModule implements PromotionsState {
    public promotions: Promotion[] = [];
    public isLoading: boolean = true;
    public isCached: boolean = false;

    @MutationAction({mutate: ['promotions', 'isLoading', 'isCached']})
    public async fetch() {
        try {
            if (!this.isCached) {
                const promotions = await getPromotions();

                return {promotions, isLoading: false, isCached: true};
            }
        } catch (err) {
            console.error('Error loading promotions', err);
            return {promotions: [], isLoading: false, isCached: false};
        }
    }
}
