import Vue from 'vue';
import Vuex from 'vuex';

import { IModal, ModalModule } from './modules/modal';
import {
    CaseCategoriesModule,
    CaseCategoriesState,
} from './modules/caseCategories';
import { UserModule } from '@/store/modules/user';
import { PromotionsModule, PromotionsState } from '@/store/modules/promotions';
import { UserAccount } from '@/types/User';
import { EventModule, EventState } from '@/store/modules/event';
import { RefillModule, RefillState } from '@/store/modules/refill';

Vue.use(Vuex);

export interface State {
    user: UserAccount;
    modal: IModal;
    caseCategories: CaseCategoriesState;
    promotions: PromotionsState;
    event: EventState;
    refill: RefillState;
}

export default new Vuex.Store<State>({
    state: {
        // @ts-ignore
        season: 'autumn',
    },
    modules: {
        user: UserModule,
        caseCategories: CaseCategoriesModule,
        modal: ModalModule,
        promotions: PromotionsModule,
        event: EventModule,
        refill: RefillModule,
    },
});
