import Axios from 'axios';
import { Promotion } from '@/types/Promotion';
import axiosRetry from 'axios-retry';
import { MOCK_GET_PROMOTIONS } from '@/requests/Promotions/getPromotions/mockGetPromotions';

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});

export default async function getPromotions(): Promise<Promotion[]> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_GET_PROMOTIONS;
    }
    return (await Axios.post('/api/promotions/get')).data
        .promotions;
}
