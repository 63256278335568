import Axios from 'axios';
import { Item } from '@/types/Item';
import { MOCK_GET_EVENT_TOP } from '@/requests/Event/getTop/getMockEventTop';
import { User } from '@/types/User';
import axiosRetry from 'axios-retry';

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});

export interface EventPlayer extends User {
    eventScore: number;
    wins: number;
    needScore?: number;
    tillLevel?: number;
}

export interface GetEventTopResponse {
    prizeItems: Pick<
        Item,
        'id' | 'image' | 'price' | 'name' | 'color' | 'type'
    >[];
    players: EventPlayer[];
    userPlace?: number;
    userScore?: number;
    userWins?: number;
}

export type EventTopFilter = 'all' | 'day';

export default async function getEventTop(filter: EventTopFilter): Promise<GetEventTopResponse> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_GET_EVENT_TOP;
    }

    return (await Axios.get<GetEventTopResponse>(`/api/event/top?filter=${filter}`)).data;
}
