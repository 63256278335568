import {
    Module,
    Mutation,
    MutationAction,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store';
import { Case } from '@/types/Case';
import { getUserAccount } from '@/requests/User/getUser/getUser';
import { UserAccount, UserAuthProvider, UserDrop } from '@/types/User';
import { FarmCases } from '@/types/FreeCase';
import Axios from 'axios';
import { countMetricPayment } from '@/requests/Payment/countMetricPayment';
import { ENABLED_COUNTRIES } from '@/constants/enabledCountries';

@Module({namespaced: true, name: 'user', store})
export class UserModule extends VuexModule implements UserAccount {
    public uuid: string = '';
    public name: string = '';
    public avatar: string = '';
    public balance: number = 0;
    public mana: number = 0;
    public eventScore: number = 0;
    public provider: UserAuthProvider = 'vkontakte';
    public providerId: string = '';
    public tradeLink: string = '';
    public loginSteam: string = '';
    public country: string = '';
    public bonusCases: Case[] = [];
    public farmCases: FarmCases = {};
    public bestDrop: UserDrop | null = null;
    public favoriteCase: UserAccount['favoriteCase'] | null = null;
    public isCached: boolean = false;

    @Mutation
    public update(user: Partial<UserAccount>) {
        Object.assign(this, {
            ...user,
        });
    }

    get isAuthorized() {
        return !!this.uuid;
    }

    @Mutation setCached(isCached: boolean) {
        this.isCached = isCached;
    }

    @MutationAction({
        mutate: [
            'name',
            'avatar',
            'uuid',
            'eventScore',
            'balance',
            'mana',
            'provider',
            'providerId',
            'tradeLink',
            'loginSteam',
            'bonusCases',
            'farmCases',
            'bestDrop',
            'favoriteCase',
            'country',
            'isCached',
        ],
    })
    public async fetch() {
        try {
            if (!this.isCached) {
                const user = await getUserAccount();
                const storageCountry = localStorage.getItem('selectedCountry');
                const userCountry = user.country?.toLowerCase();
                const country = ENABLED_COUNTRIES.find((country) => country.code === (storageCountry ?? userCountry ?? 'other'))?.code;

                if (user.payment) {
                    // @ts-ignore
                    window.gtag('event', 'purchase', {
                        transaction_id: user.payment.id,
                        affiliation: user.payment.promoCampaign,
                        coupon: user.payment.promo,
                        value: user.payment.amount,
                        currency: 'RUB',
                        items: [
                            {
                                name: user.payment.promo?.includes('Акция') ? user.payment.promo : `Пополнение баланса на ${user.payment.to_balance}₽`,
                                price: user.payment.amount
                            }
                        ]
                    });
                    countMetricPayment(user.payment.id);
                }

                return {...user, country: country, isCached: true};
            }
        } catch (err) {
            console.error('Error loading user account', err);

            return {
                name: '',
                avatar: '',
                uuid: '',
                eventScore: 0,
                balance: 0,
                mana: 0,
                provider: '',
                providerId: '',
                tradeLink: '',
                loginSteam: '',
                country: null,
                bestDrop: null,
                favoriteCase: null,
                bonusCases: [],
                farmCases: [],
                isCached: false,
            };
        }
    }
}
