import { Module, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { PaymentMethod } from '@/types/PaymentCountry';
import getMethods from '@/requests/Refill/getMethods/getMethods';

export interface RefillState {
    methods: PaymentMethod[];
    rates: Record<string, number>;
    isLoading: boolean;
}

@Module({namespaced: true, name: 'refill', store})
export class RefillModule extends VuexModule implements RefillState {
    public methods: PaymentMethod[] = [];
    public rates: Record<string, number> = {};
    public isLoading: boolean = true;
    public isCached: boolean = false;

    @MutationAction({mutate: ['methods', 'rates', 'isLoading', 'isCached']})
    public async fetch() {
        try {
            if (!this.isCached) {
                const {methods, rates} = await getMethods();

                return {methods, rates: {...rates, RUB: 1}, isLoading: false, isCached: true};
            }
        } catch (err) {
            console.error('Error loading pay methods', err);
            return {methods: [], rates: {}, isLoading: false, isCached: false};
        }
    }
}
