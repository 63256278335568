import 'core-js/stable';

import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import VueSocketIO from 'vue-socket.io-extended';
import io from 'socket.io-client';
import VueNotification from 'vue-notification';
import VuePageTitle from '@homerun/vue-page-title';
// @ts-ignore
import VueAnalytics from 'vue-ua';
import VTooltip from 'v-tooltip';
import InlineSvg from 'vue-inline-svg';
import VueScrollReveal from 'vue-scroll-reveal';

import '@/assets/scss/styles.scss';

import App from '@/App.vue';
import store from '@/store';
import router from '@/router';

declare module 'vue/types/vue' {
  interface Vue {
    $router: VueRouter;
    $route: Route;
    $sr: any;
    $setPageTitle: (title: string) => void;
  }
}

Vue.config.productionTip = false;

Vue.use(VueSocketIO, io(window.location.origin));
Vue.use(VueAnalytics, {
  appName: 'DOTALOOT',
  appVersion: '1.0',
  trackingId: 'UA-154889105-1',
  vueRouter: router,
  trackPage: true
});

Vue.use(VueNotification);
Vue.use(VTooltip);
Vue.use(VueScrollReveal);
Vue.use(VuePageTitle);
Vue.component('InlineSvg', InlineSvg);

Vue.directive('click-outside', {
  bind: function(el, binding, vnode) {
    el.clickOutsideEvent = function(event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind: function(el) {
    // @ts-ignore
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app');
