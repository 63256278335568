import { Module, MutationAction, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import { CaseCategory } from '@/types/Case';
import getCases from '@/requests/Cases/getCases/getCases';

export interface CaseCategoriesState {
    categories: CaseCategory[];
    isLoading: boolean;
}

@Module({namespaced: true, name: 'caseCategories', store})
export class CaseCategoriesModule extends VuexModule implements CaseCategoriesState {
    public categories: CaseCategory[] = [];
    public isLoading: boolean = true;
    public isCached: boolean = false;

    get farmCategories() {
        const farmCategory = this.categories.find(_ => _.id === 4);

        return farmCategory ? [farmCategory] : [];
    }

    @MutationAction({mutate: ['categories', 'isLoading', 'isCached']})
    public async fetch() {
        try {
            if (!this.isCached) {
                const categories = await getCases();

                return {categories, isLoading: false, isCached: true};
            }
        } catch (err) {
            console.error('Error loading case categories', err);
            return {categories: [], isLoading: false, isCached: false};
        }
    }
}
