import Axios from 'axios';

import axiosRetry from 'axios-retry';
import { PaymentMethod } from '@/types/PaymentCountry';
import { MOCK_GET_METHODS } from '@/requests/Refill/getMethods/mockGetMethods';

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});

interface MethodsResponse {
    methods: PaymentMethod[];
    rates: Record<string, number>;
}

export default async function getMethods(): Promise<MethodsResponse> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_GET_METHODS as MethodsResponse;
    }
    return (await Axios.post('/api/payment/get')).data;
}
