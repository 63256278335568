import { UserAccount } from '@/types/User';
import { addHours } from 'date-fns';

export const MOCK_GET_USER_ACCOUNT: UserAccount = {
    country: 'KZ',
    uuid: '7f74c302-c67d-4191-a46c-4f53ea64a312',
    name: 'Nikita Sakharov',
    avatar: 'https://vk.com/images/camera_200.png',
    balance: 4622,
    mana: 3367,
    provider: 'vkontakte',
    providerId: '1',
    eventScore: 100,
    tradeLink:
        'https://steamcommunity.com/tradeoffer/new/?partner=123456789&token=123456789',
    loginSteam: 'https://steamcommunity.com/id/123456789',
    bestDrop: {
        status: 1,
        id: 1,
        item: {
            id: 1,
            name: 'Item',
            price: 900,
            image:
                '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUnpx9YXkDfVeDiwsHXVk94KhZTia6sczhp3v7HYylD4OOhkYGbmPm7JrXVk3ld59d1tf3IrbP8hVm7uQc9Pwa0cdfVLVggYVDYrwO5yO7m05LoupiYmnBq6HQk4CnZzhDi1EtLPLQ6gvLLHVjPUbsJQvfH1J973g',
        },
        source: 'case',
    },
    favoriteCase: {
        id: 1,
        image:
            '-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KW1Zwwo4NUX4oFJZEHLbXK9QlSPcUxpg5PRUnCCPOs1c3aRxJjJAZVv67rKFMz0vLOd2gXvIXil9XZzvKhZ72CwmoCupQm2OzD84ij3AHmrkA6NXezetFuB52vBg',
        name: 'Best Case Ever',
    },
    bonusCases: [
        {
            id: 999,
            name: 'Кейсик',
            price: 111,
            image: '',
            label: 'hot',
            eventPoints: 20,
        },
        {
            id: 53,
            name: 'Кейсик',
            price: 111,
            image: '',
            label: 'hot',
            eventPoints: 20,
        },
    ],
    farmCases: {
        10001: {
            conditionMet: true,
            nextOpenAt: addHours(new Date(), 2).toISOString()
        },
        10002: {
            conditionMet: true,
            nextOpenAt: null
        },
        10003: {
            conditionMet: false,
            nextOpenAt: null
        }
    }
};
