import Axios from 'axios';
import { CaseCategory } from '@/types/Case';
import { MOCK_GET_CASES } from '@/requests/Cases/getCases/getMockCases';
import axiosRetry from 'axios-retry';

axiosRetry(Axios, {retries: 4, retryDelay: axiosRetry.exponentialDelay});
export default async function getCases(): Promise<CaseCategory[]> {
    if (process.env.NODE_ENV === 'development') {
        return MOCK_GET_CASES;
    }

    let categories;

    while (!categories || !categories.length) {
        categories = (await Axios.post<{ categories: CaseCategory[] }>('/api/case/get'))
            .data.categories;
    }

    return categories;
}
